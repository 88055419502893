<template>
  <div class="outer-page">
    <div class="title">
      <selectPane :monitorInfo="monitorInfo"></selectPane>
    </div>
    <div
      class="echarts-pane"
      ref="myEchart"
      style="width: 100%; height: 300px"
    ></div>
  </div>
</template>

<script>
import selectPane from "../selectPane";
import * as echarts from "echarts";
export default {
  components: {
    selectPane,
  },
  props: {
    staticsInfo: {
      type: [Object, Array],
      default: () => {
        return {};
      },
    },
    monitorInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  name: "",
  data() {
    return {
      xData: [],
      yData: [],
      minData: [],
      maxData: [],
    };
  },
  methods: {
    initChart() {
      let chart = echarts.init(this.$refs.myEchart);
      let option = {
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          data: this.xData,
          // [
          //   "09:00:00",
          //   "10:00:00",
          //   "11:00:00",
          //   "12:00:00",
          //   "13:00:00",
          //   "14:00:00",
          //   "15:00:00",
          // ],
          //字体样式
          axisLabel: {
            show: true,
            //坐标轴刻度标签的显示间隔
            interval: 4,
            textStyle: {
              color: "#333",
            },
          },
          //数据两端留白
          boundaryGap: false,
          //轴刻度线
          axisTick: {
            show: false,
          },
          //轴底部坐标轴线条
          axisLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#eee",
            },
          },
          //X轴网格线
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: true,
            textStyle: {
              color: "#333",
            },
          },
          max: 40,
          minInterval: 0,
          //跨度值
          interval: 10,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
        grid: {
          containLabel: true,
          height: "94%",
          width: "96%",
          left: "2%",
          top: "6%",
        },
        series: [
          {
            name: "",
            type: "line",
            smooth: true,
            color: "#08DDDD",
            lineStyle: {
              show: true,
            },
            showSymbol: false,
            label: {
              show: true,
              position: "top",
            },
            emphasis: {
              focus: "series",
            },
            data: this.yData,
            // [20, 30, 20, 30, 23, 22, 20, 25],
          },
          //最小值
          {
            name: "",
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            type: "line",
            stack: "总量",
            areaStyle: {
              color: "white",
            },
            emphasis: {
              focus: "series",
            },
            data: this.minData,
            // [20, 20, 20, 20, 20, 20, 20],
          },
          //最大值
          {
            name: "",
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            type: "line",
            stack: "总量",
            areaStyle: {
              color: "#B7E9E3",
            },
            emphasis: {
              focus: "series",
            },
            data: this.maxData,
            // [10, 10, 10, 10, 10, 10, 10],
          },
        ],
      };
      chart.setOption(option);
    },
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.initChart();
    // });
  },
  watch: {
    staticsInfo(n) {
      this.xData = [];
      this.yData = [];
      this.minData = [];
      this.maxData = [];
      let a = Number(n[0].value);
      let b = Number(n[0].value);
      for (let i = 0; i < n.length; i++) {
        //最小值
        if (a > Number(n[i].value)) {
          a = Number(n[i].value);
        }
        //最大值
        if (b < Number(n[i].value)) {
          b = Number(n[i].value);
        }
      }
      if (n) {
        n.forEach((item) => {
          this.xData.push(item.showAllTime);
          this.yData.push(item.value);
          this.minData.push(a);
          this.maxData.push(b - a);
        });
        this.$nextTick(() => {
          this.initChart();
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  .title {
    padding: 10px;
    margin-bottom: 10px;
  }
  .echarts-pane {
    padding: 10px;
    width: 100%;
  }
}
</style>