<template>
  <div class="outer-title">
    <div class="title-left">
      <span
        >最后更新时间：{{
          monitorInfo.updateTime ? monitorInfo.updateTime : ""
        }}</span
      >
      <span style="color: #60cfc8"
        >{{ monitorInfo.count ? monitorInfo.count : 0
        }}{{ monitorInfo.unit ? monitorInfo.unit : "" }}</span
      >
      <span
        >{{ monitorInfo.type ? monitorInfo.type : "" }}参考值({{
          monitorInfo.reference ? monitorInfo.reference : ""
        }}{{ monitorInfo.unit ? monitorInfo.unit : "" }})</span
      >
    </div>
    <div class="title-right">
      <!-- <ul>
        <li class="actives">今日</li>
        <li>本周</li>
        <li>本月</li>
        <li>全年</li>
      </ul>
      <DatePicker
        type="daterange"
        placement="bottom-end"
        placeholder="请选择日期查询"
        style="width: 260px"
        transfer
      ></DatePicker> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    /* 
      最后更新时间:updateTime,
      含量:count,
      单位:unit,
      参考值:reference
      类型:type
      */
    monitorInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.outer-title {
  display: flex;
  justify-content: space-between;
  .title-left {
    display: flex;
    align-items: center;
    > span {
      margin-right: 20px;
    }
  }
  .title-right {
    display: flex;
    > ul {
      display: flex;
      align-items: center;
      li {
        display: inline-block;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
  .actives {
    color: #60cfc8;
  }
}
</style>